
























































import { Vue, Prop, Component } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import { Validations } from 'vuelidate-property-decorators'
import {
  required,
  minLength,
  maxLength,
  ValidationRule,
} from 'vuelidate/lib/validators'
import {
  MAX_NOTES_REJECT_APPROVING_TOPUP,
  MIN_NOTES_REJECT_APPROVING_TOPUP,
} from '@/app/infrastructures/misc/Constants/validation'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import { validationMixin } from 'vuelidate'

interface ValidationsInterface {
  form: {
    notes: {
      required: () => ValidationRule
      minLength: ValidationRule
      maxLength: ValidationRule
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    Modal,
    TextInput,
    Button,
  },
})
export default class ModalReject extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: 'Title' }) private title!: string
  @Prop({ default: 'Sub Title' }) private subtitle!: string
  @Prop({ default: 'Message' }) private message!: string
  @Prop({ default: 'Hint' }) private hint!: string
  @Prop({ default: false }) private isDisabled!: boolean

  form = {
    notes: '',
  }

  private handleClose() {
    this.clearForm()
    this.$emit('close')
  }

  private handleReject() {
    this.$emit('reject', this.form.notes)
    this.clearForm()
  }

  private clearForm() {
    this.form.notes = ''
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        notes: {
          required,
          minLength: minLength(MIN_NOTES_REJECT_APPROVING_TOPUP),
          maxLength: maxLength(MAX_NOTES_REJECT_APPROVING_TOPUP),
        },
      },
    }
  }
}
