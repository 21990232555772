






























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import {
  Utils,
  APPROVING_TOPUP_SALDO_PAGINATION,
  MODAL_REJECT_REQUEST_TOPUP,
  MODAL_SUCCESS_APPROVE_TOPUP,
  MODAL_SUCCESS_REJECT_TOPUP,
  EnumTypeBalanceStatus,
  EventBusConstants,
  EnumBalanceID,
} from '@/app/infrastructures/misc'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import controller, {
  RequestTopupListParams,
} from '@/app/ui/controllers/RequestTopupSaldoController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import ModalApproveBulky from '../components/ModalApproveBulky/index.vue'
import ModalReject from '../components/AdjustmentModals/ModalReject/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import { Dictionary } from 'vue-router/types/router'

interface dataBulkApproveInterface {
  customerId: number
  phoneNumber: string
  amount: string | number
  note: string
}

export interface modalSuccessInterface {
  visible: boolean
  title: string
  subtitle: string
}

@Component({
  components: {
    TextInput,
    DropdownSelect,
    DataTableV2,
    Button,
    EmptyState,
    PaginationNav,
    LoadingOverlay,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    ModalApproveBulky,
    ModalReject,
    Modal,
  },
})
export default class ApprovingTopupSaldo extends Vue {
  controller = controller
  isSuperAdmin = Utils.isSuperAdmin()
  sortOptions = [
    { label: 'Terbaru', value: 'NEWEST' },
    { label: 'Terlama', value: 'OLDEST' },
  ]
  dataChecked: Array<number> = []
  showModalReject = false
  showModalApprove = false
  dataBulkApprove: Array<dataBulkApproveInterface | undefined> = []
  MODAL_REJECT_REQUEST_TOPUP = MODAL_REJECT_REQUEST_TOPUP
  modalSuccess: modalSuccessInterface = {
    title: '',
    subtitle: '',
    visible: false,
  }

  headers = [
    {
      title: '',
      customStyle: { maxWidth: '50px', minWidth: '50px' },
    },
    {
      title: 'Customer ID',
      customStyle: { maxWidth: '150px', minWidth: '150px' },
    },
    {
      title: 'Phone Number',
      customStyle: { maxWidth: '150px', minWidth: '150px' },
    },
    {
      title: 'Total Saldo',
      customStyle: { maxWidth: '150px', minWidth: '150px' },
    },
    {
      title: 'Request Date',
      customStyle: { maxWidth: '200px', minWidth: '200px' },
    },
    {
      title: 'Requestor',
      customStyle: { maxWidth: '300px', minWidth: '300px' },
    },
    {
      title: 'Action',
      customStyle: { maxWidth: '200px', minWidth: '200px' },
    },
  ]

  parameters = {
    sortBy: this.sortOptions[0],
    page: 1,
    perPage: APPROVING_TOPUP_SALDO_PAGINATION,
    filterStatus: 'REQUESTED',
    phoneNumber: '',
  }

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        sortBy:
          this.sortOptions.find(item => item.value === queries.sortBy) ||
          this.sortOptions[0],
        phoneNumber: decodeURIComponent(queries.phoneNumber || ''),
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: APPROVING_TOPUP_SALDO_PAGINATION,
        filterStatus: 'REQUESTED',
      }
    }

    this.fetchApprovingTopupList(true)
    controller.setStatusApproveRejectBulky('')
  }

  get params(): RequestTopupListParams {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      phoneNumber: encodeURIComponent(this.parameters.phoneNumber),
    }
  }

  setNegativeOrMinus(val: string | undefined): string {
    return val === EnumBalanceID.DEDUCT ? '-' : ''
  }

  get dataApprovingTopupList(): Array<DataObject[]> {
    return this.controller.listRequestTopupHistory?.map(item => {
      return [
        {
          value: item.balanceId,
          customStyle: { maxWidth: '50px', minWidth: '50px' },
        },
        {
          value: item.customerId || '-',
          customStyle: { maxWidth: '150px', minWidth: '150px' },
        },
        {
          value: item.phoneNumber
            ? Utils.countryIndonesiaPhoneNumber(item.phoneNumber, true)
            : '-',
          customStyle: { maxWidth: '150px', minWidth: '150px' },
        },
        {
          value: `${this.setNegativeOrMinus(
            item.balanceTypeID
          )}${Utils.toRupiah(Math.abs(item.totalSaldoRequest || 0))}`,
          customStyle: { maxWidth: '150px', minWidth: '150px' },
        },
        {
          value: item.createdAt
            ? Utils.formatTimeZone(
                Utils.formatDate(item.createdAt, 'DD MMM YYYY[\n]HH:mm Z')
              )
            : '-',
          customStyle: { maxWidth: '200px', minWidth: '200px' },
        },
        {
          value: item.requestor,
          customStyle: { maxWidth: '300px', minWidth: '300px' },
        },
        {
          value: `${item.customerId}-${item.balanceId}`,
          customStyle: { maxWidth: '200px', minWidth: '200px' },
        },
      ]
    })
  }

  private fetchApprovingTopupList(resetPage = false): void {
    this.dataChecked = []
    if (resetPage) {
      this.parameters.page = 1
    }

    this.controller.getHistoryRequestTop(this.params)
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router
      .replace({
        query: { ...val },
      })
      .catch(() => false)
  }

  @Watch('dataChecked')
  onDataCheckedChange(): void {
    const dataFilter = this.controller.listRequestTopupHistory?.filter(item =>
      this.dataChecked.includes(Number(item.balanceId))
    )
    this.dataBulkApprove = dataFilter.map((item, idx) => {
      if (item.balanceId === this.dataChecked[idx]) {
        return {
          customerId: Number(item.customerId),
          phoneNumber: item.phoneNumber || '-',
          amount: `${this.setNegativeOrMinus(
            item.balanceTypeID
          )}${Utils.toRupiah(Math.abs(item.totalSaldoRequest || 0))}`,
          note: item.note || '-',
        }
      }
    })
  }

  private checkListOne(id: number): void {
    if (!this.isSuperAdmin) return
    if (this.dataChecked.includes(id)) {
      this.dataChecked = this.dataChecked.filter(item => {
        return item !== id
      })
      return
    }
    this.dataChecked.push(id)
  }

  private checkListAll(): void {
    if (!this.isSuperAdmin) return
    if (this.dataChecked.length === this.dataApprovingTopupList.length) {
      this.dataChecked = []
      return
    }
    this.dataApprovingTopupList.forEach(item => {
      if (!this.dataChecked.includes(Number(item[0].value))) {
        this.dataChecked.push(Number(item[0].value))
      }
    })
  }

  private rejectBulk(): void {
    if (!this.isSuperAdmin) return
    this.showModalReject = true
  }

  private approveBulk(): void {
    if (!this.isSuperAdmin) return
    this.showModalApprove = true
  }

  private handleCheckAgain(): void {
    this.showModalApprove = false
  }

  private handleReject(note: string): void {
    if (!this.isSuperAdmin) return
    controller.approveRejectBulky({
      balanceStatus: EnumTypeBalanceStatus.REJECTED,
      balanceIDs: this.dataChecked,
      reasonNote: note,
    })
  }

  private handleApprove(): void {
    if (!this.isSuperAdmin) return
    controller.approveRejectBulky({
      balanceStatus: EnumTypeBalanceStatus.APPROVED,
      balanceIDs: this.dataChecked,
      reasonNote: '',
    })
  }

  private redirectToDetail(data: number): void {
    this.$router.push({
      name: 'ApprovingTopUpSaldoDetailPage',
      params: { id: String(data) },
    })
  }

  private resetModalSuccess() {
    this.modalSuccess = {
      title: '',
      subtitle: '',
      visible: false,
    }
  }

  @Watch('controller.statusApproveRejectBulky', { deep: true })
  onStatusApproveRejectBulky(status: string): void {
    if (status !== '') {
      switch (status) {
        case EventBusConstants.APPROVE_SALDO_BULKY_SUCCESS:
          this.showModalApprove = false
          this.modalSuccess = {
            visible: true,
            title: MODAL_SUCCESS_APPROVE_TOPUP.title,
            subtitle: MODAL_SUCCESS_APPROVE_TOPUP.subtitle,
          }
          this.fetchApprovingTopupList(true)
          break
        case EventBusConstants.REJECT_SALDO_BULKY_SUCCESS:
          this.showModalReject = false
          this.modalSuccess = {
            visible: true,
            title: MODAL_SUCCESS_REJECT_TOPUP.title,
            subtitle: MODAL_SUCCESS_REJECT_TOPUP.subtitle,
          }
          this.fetchApprovingTopupList(true)
          break
        case EventBusConstants.APPROVE_SALDO_BULKY_FAILED:
          this.handleCheckAgain()
          break
        case EventBusConstants.REJECT_SALDO_BULKY_FAILED:
          this.showModalReject = false
          break
      }
      controller.setStatusApproveRejectBulky('')
    }
  }

  beforeDestroy(): void {
    controller.setStatusApproveRejectBulky('')
  }
}
