








































import { Component, Prop, Vue } from 'vue-property-decorator'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import { IObjectMultiLevel } from './type'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'

@Component({
  components: {
    ExpandIcon,
    IconWarningTriangle,
  },
})
export default class MultiLevel extends Vue {
  @Prop({ type: Number, default: 5 }) totalLevel!: number
  @Prop({ type: Array, required: true }) ObjectMultiLevel!: Array<
    IObjectMultiLevel
  >
  @Prop({ type: String, default: '#FF0000'}) borderColor!: string
}
