



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import MultiLevel from '@/app/ui/components/MultiLevel/index.vue'
import { IObjectMultiLevel } from '@/app/ui/components/MultiLevel/type'
import Button from '@/app/ui/components/Button/index.vue'
import { Utils } from '@/app/infrastructures/misc'

interface dataInterface {
  customerId: number
  phoneNumber: string
  amount: string | number
  note: string
  transaction?: string
}

@Component({
  components: {
    Modal,
    MultiLevel,
    Button,
  },
})
export default class ModalApproveBulky extends Vue {
  @Prop({ type: Array, required: true }) data!: Array<dataInterface>
  @Prop({ type: Boolean, required: true }) visible!: boolean
  @Prop({ type: Boolean, default: false }) isLoading!: boolean
  @Prop({ type: String, default: 'saldo'}) type!: 'saldo' | 'poin'

  get ObjectMultiLevel(): Array<IObjectMultiLevel> {
    return this.data.map(item => {
      return {
        prefix: String(item.customerId),
        isOpen: true,
      }
    })
  }

  private handleCheckAgain(): void {
    if (this.isLoading) return
    this.$emit('check-again')
  }

  private handleApprove(): void {
    if (this.isLoading) return
    this.$emit('approve')
  }

  private customClassPoin(amount: number): string {
    if (this.type === 'poin') {
      if (amount > 0) {
        return 'text-green-2'
      } else {
        return 'text-red-1'
      }
    }
    return 'text-black'
  }

  private getAmount(amount: number): string {
    if (this.type === 'poin') {
      if (amount > 0) {
        return `+${amount}`
      } else {
        return String(amount)
      }
    }
    return String(amount)
  }

  private getPhoneNumber(phone: string) {
    return Utils.countryIndonesiaPhoneNumber(phone, true)
  }
}
